import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { submitForm } from "../lib/airtable";
import "./Survey.css";

const radioValues = [1, 2, 3, 4, 5]

const questions = [
  {
    label: "I think this was one of the most important assemblies/meetings I have attended at my school.",
    name: "question 1",
    questionType: "radio",
  },
  {
    label: "I learned how mental health challenges and substance misuse can affect anyone.",
    name: "question 2",
    questionType: "radio",
  },
  {
    label: "I learned mental health and substance issues are illnesses, and people struggling should get help.",
    name: "question 3",
    questionType: "radio",
  },
  {
    label: "I learned talking with a friend who is struggling can be an important first step to helping them.",
    name: "question 4",
    questionType: "radio",
  },
  {
    label: "I think my school should have meetings about mental health issues more often.",
    name: "question 5",
    questionType: "radio",
  },
  {
    label: "What more can Generation S.O.S. do to support you and your peers?",
    name: "question 6",
    questionType: "text",
  },
];

function Form() {
  const [data, setData] = useState({});
  const navigate = useNavigate();

  function handleSubmit(e) {
    e?.preventDefault();

    submitForm(data).then(() => {
      navigate("/ExploreMore");
    });
  }

  return (
    <div>
      <div className="survey-wrapper">
        <div className="instructions">
        <div>Please answer the following questions where 1 is "disagree strongly" and  5 is "agree strongly". </div>
        </div>
        <br />
        <br />
        {/* same <form> element as before  */}
        <form className="form" onSubmit={handleSubmit}>
          {/* remember, map() works like a for loop
            for each question and idx in questions:
               execute everything inside the <div */}
          {questions.map((question, idx) => (
            <div className="check-box-group" key={idx}>
              {/* we apply our same font-link styling to the label */}
              {/* we access the label of each question using .label, see questions variable */}
              <div className="font-link">{question.label}</div>
              {question.questionType === "radio" ? (
                <div> 
                  {radioValues.map((num) => (
                   <label key={question.name + num}>
                   <input  
                    type="radio"
                     // checked is if the data (what the user inputs) of
                        // that question === num
                        // this is how we keep track of which circle is checked
                    checked={data[question.name] === num}
                        onChange={() => {
                          const newData = {
                            ...data,
                            [question.name]: num,
                          };

                          setData(newData);
                        }}/>
                        <div>
                          {num}
                        </div>
                        </label> 
                  ))}
                </div>
              ):(<textarea className="form-box" value={data[question.name] || ""}
              onChange={(e) => {
                const newData = {
                  ...data,
                  [question.name]: e.target.value,
                };

                setData(newData);
              }}/>
              )}
            </div>
          ))}
          {/* we have a submit button as before */}
          <button className="submit" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Form;
