import Airtable from "airtable";

const base = new Airtable({ apiKey: "keyOTOXsw6OXRNoFj" }).base(
  "app338LCR838imhIa"
);

export async function submitForm(data) {
  return new Promise((resolve) => {
    base("Table 1").create(
      [
        {
          fields: data,
        },
      ],
      function (err, records) {
        if (err) {
          console.error(err);
          return;
        }

        resolve(records);
      }
    );
  });
}
