import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import "./Example.css";
import logoPic from "./assets/SOS.png";
import backgroundPic from "./assets/background.jpg";
import ExploreMore from "./components/ExploreMore";
import Survey from "./components/Survey";
import "./components/fonts.css";

function App() {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundPic})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      <Router>
        <a href="https://www.generationsos.org/">
          <img src={logoPic} alt="logo" className="logo" />
        </a>

        <Routes>
          <Route path="/ExploreMore" element={<ExploreMore />} />
          <Route path="/" element={<Survey />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
